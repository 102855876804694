// src/components/PaidPass/UpdatePaidPass.jsx

import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Spinner,
  Button,
  Label,
  Input,
  FormGroup,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { putRequest } from "../../components/Common/Utils.js"; // Ensure this utility is correctly implemented

const UpdatePaidPass = () => {
  const history = useHistory();
  const location = useLocation();

  // Extract the existing Paid Pass data from state
  const existingData = location.state?.row;

  const [paidPassData, setPaidPassData] = useState({
    id: existingData?._id || "", // Ensure ID is included
    title: existingData?.title || "",
    description: existingData?.description || "",
    type: existingData?.type || "venue",  
    amount: existingData?.amount || "",
    validityInDays: existingData?.validityInDays || 0,
    status: existingData?.status || false,
  });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!existingData) {
      toast.error("No data available to update.");
      history.push("/paid-pass"); // Redirect if no data is available
    }
  }, [existingData, history]);

  // Handle input changes
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setPaidPassData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Basic Validation
    if (!paidPassData.title.trim()) {
      toast.error("Title is required");
      return;
    }
    if (!paidPassData.description.trim()) {
      toast.error("Description is required");
      return;
    }

    // Prepare data to send
    const payload = {
      id: paidPassData.id, // Include the ID for the update
      title: paidPassData.title,
      description: paidPassData.description,
      type: paidPassData.type,     
      amount: paidPassData.amount ? Number(paidPassData.amount) : undefined,
      validityInDays: Number(paidPassData.validityInDays),
      status: paidPassData.status,
    };

    try {
      setLoading(true);
      const response = await putRequest("promoter/paid-pass/update", payload);

      if (response.status === 1) {
        toast.success(response.message);
        // Redirect after a short delay to allow the user to read the message
        setTimeout(() => {
          history.push("/paid-pass"); // Adjust the path as needed
        }, 2000);
      } else {
        toast.error(response.message || "Failed to update Paid Pass");
      }
    } catch (error) {
      console.error("Error updating Paid Pass:", error);
      toast.error("An unexpected error occurred");
    } finally {
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <ToastContainer />

      <div className="row">
        <div className="col-lg-10">
          <div className="page-title-box">
            <h4>Paid Pass Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Paid Pass Management</li>:{" "}
              <li className="breadcrumb-item">Update Paid Pass</li>
            </ol>
          </div>
        </div>
        <div className="col-md-2 text-end">
          <Button color="primary" size="lg" onClick={() => history.goBack()}>
            <i className="fas fa-chevron-left"></i> Back
          </Button>
        </div>
      </div>

      <Row>
        <Col lg={12}>
          <Card>
            {loading ? (
              <CardBody style={{ height: "100px", position: "relative" }}>
                <Spinner
                  color="info"
                  style={{
                    height: "4rem",
                    width: "4rem",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                >
                  Loading...
                </Spinner>
              </CardBody>
            ) : (
              <CardBody>
                <h4 className="card-title">Update Paid Pass</h4>
                <Form onSubmit={handleSubmit}>
                  {/* Title */}
                  <div className="row mb-4">
                    <Label className="col-sm-3 col-form-label" for="title">
                      Title <span style={{ color: "red" }}>*</span>
                    </Label>
                    <div className="col-sm-6">
                      <Input
                        type="text"
                        name="title"
                        id="title"
                        placeholder="Enter title"
                        value={paidPassData.title}
                        onChange={handleChange}
                        required
                        className="form-control"
                      />
                    </div>
                  </div>

                  {/* Description */}
                  <div className="row mb-4">
                    <Label className="col-sm-3 col-form-label" for="description">
                      Description <span style={{ color: "red" }}>*</span>
                    </Label>
                    <div className="col-sm-6">
                      <Input
                        type="textarea"
                        name="description"
                        id="description"
                        placeholder="Enter description"
                        value={paidPassData.description}
                        onChange={handleChange}
                        required
                        className="form-control"
                        style={{ height: "150px" }}
                      />
                    </div>
                  </div>

                  {/* Type */}
                  <div className="row mb-4">
                    <Label className="col-sm-3 col-form-label" for="type">
                      Type
                    </Label>
                    <div className="col-sm-6">
                      <Input
                        type="select"
                        name="type"
                        id="type"
                        value={paidPassData.type}
                        onChange={handleChange}
                        className="form-control"
                      >                      
                        <option value="venue">Venue</option>
                        <option value="event">Event</option>
                      </Input>
                    </div>
                  </div>
                
                 

                  {/* Amount */}
                  <div className="row mb-4">
                    <Label className="col-sm-3 col-form-label" for="amount">
                      Amount
                    </Label>
                    <div className="col-sm-6">
                      <Input
                        type="number"
                        name="amount"
                        id="amount"
                        placeholder="Enter amount"
                        value={paidPassData.amount}
                        onChange={handleChange}
                        min="0"
                        step="0.01"
                        className="form-control"
                      />
                    </div>
                  </div>

                  {/* Validity in Days */}
                  <div className="row mb-4">
                    <Label className="col-sm-3 col-form-label" for="validityInDays">
                      Validity (Days)
                    </Label>
                    <div className="col-sm-6">
                      <Input
                        type="number"
                        name="validityInDays"
                        id="validityInDays"
                        placeholder="Enter validity in days"
                        value={paidPassData.validityInDays}
                        onChange={handleChange}
                        min="0"
                        className="form-control"
                      />
                    </div>
                  </div>

                  {/* Status */}
                  <div className="row mb-4">
                    <Label className="col-sm-3 col-form-label" for="status">
                      Status
                    </Label>
                    <div className="col-sm-6">
                      <FormGroup className="form-check">
                        <Input
                          type="checkbox"
                          name="status"
                          id="status"
                          checked={paidPassData.status}
                          onChange={handleChange}
                          className="form-check-input"
                        />
                        <Label className="form-check-label" for="status">
                          Active
                        </Label>
                      </FormGroup>
                    </div>
                  </div>

                  {/* Submit Button */}
                  <div className="mt-5 mt-lg-4">
                    <div className="row justify-content-end">
                      <div className="col-sm-6">
                        <Button
                          type="submit"
                          color="primary"
                          className="btn btn-primary w-md"
                          disabled={loading}
                        >
                          {loading ? (
                            <>
                              <Spinner size="sm" /> Submitting...
                            </>
                          ) : (
                            "Submit"
                          )}
                        </Button>
                      </div>
                    </div>
                  </div>
                </Form>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default UpdatePaidPass;
