import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import SimpleBar from "simplebar-react";
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";

const SidebarContent = (props) => {
  const ref = useRef();
  const [admin, setAdmin] = useState("");
  let dtPermission = localStorage.getItem("permissions") || null;
  console.log("dtPermission",dtPermission);
  dtPermission = dtPermission ? JSON.parse(dtPermission) : null;
  const [permissions, setPermissions] = useState(dtPermission);
  const [menuInitialized, setMenuInitialized] = useState(false);

  const metisMenuRef = useRef(null);

  useEffect(() => {
    const accType = localStorage.getItem("acc_type");
    console.log("ADMIN TYPE", accType)
    setAdmin(accType);
    const storedPermissions = JSON.parse(localStorage.getItem("permissions")) || null;
    setPermissions(storedPermissions);


 
  }, []);

  useEffect(() => {
    // Dispose previous MetisMenu instance if it exists
    if (metisMenuRef.current) {
      metisMenuRef.current.dispose();
    }

    metisMenuRef.current = new MetisMenu("#side-menu", { toggle: false });

    const pathName = props.location.pathname;
    let matchingMenuItem = null;
    const ul = document.getElementById("side-menu");
    const items = ul.getElementsByTagName("a");
    for (let i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }

    // Clean up on unmount
    return () => {
      if (metisMenuRef.current) {
        metisMenuRef.current.dispose();
      }
    };
  }, [props.location.pathname, admin, permissions]); // Add dependencies


  useEffect(() => {
    ref.current.recalculate();
  }, [admin, permissions]);


  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active");
    const parent = item.parentElement;

    if (parent) {
      parent.classList.add("mm-active");
      scrollElement(item);
    }
  }

  const renderMenuItem = (permissionKey, link, icon, title) => {
    if (admin === "admin" || admin === "business" || permissions?.[permissionKey]?.show || permissions?.[permissionKey] === true) {
      return (
        <li>
          <Link
            to={link}
            className="waves-effect"
            onClick={() => {
              // Collapse all open submenus
              document.querySelectorAll(".mm-show").forEach((el) => {
                el.classList.remove("mm-show");
                el.style.height = 0;
              });
            }}
          >
            <i className={icon}></i>
            <span>{props.t(title)}</span>
          </Link>
        </li>
      );
    }
    return null;
  };


  const renderSubMenu = (permissionKey, parentIcon, parentTitle, subMenus,show) => {
    if (admin === "admin" || admin === "business" || permissions?.[permissionKey]?.show || permissions?.[permissionKey] === true) {
      return (
        <li style={{display:show=="true"?"none":""}}>
          <Link to="#" className="has-arrow waves-effect">
            <i className={parentIcon}></i>
            <span>{props.t(parentTitle)}</span>
          </Link>
          <ul className="sub-menu mm-collapse" onClick={() => {
            // Collapse the parent submenu
            const parentSubMenu = document.querySelector(".mm-active .mm-show");
            if (parentSubMenu) {
              parentSubMenu.classList.remove("mm-show");
              parentSubMenu.style.height = 0;
            }
          }}
            aria-expanded="false">
            {subMenus.map((subMenu, index) => (
              <li key={index}>
                <Link
                  to={subMenu.link}
                  className="waves-effect"
                  onClick={() => {
                    // Collapse the parent submenu
                    const parentSubMenu = document.querySelector(".mm-active .mm-show");
                    if (parentSubMenu) {
                      parentSubMenu.classList.remove("mm-show");
                      parentSubMenu.style.height = 0;
                    }
                  }}
                >
                  <span>{props.t(subMenu.title)}</span>
                </Link>
              </li>
            ))}
          </ul>
        </li>
      );
    }
    return null;
  };



  return (
    <React.Fragment>
      {admin === "admin" || admin === "data-entry" || admin === "sub-admin" ? (
        <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
          <div id="sidebar-menu">
            <ul className="metismenu list-unstyled" id="side-menu">
              {renderMenuItem("dashboard", "/dashboard", "mdi mdi-view-dashboard", "Dashboard")}

              {renderSubMenu("usersData", "mdi mdi-account", "Users", [
                { link: "/users", title: "User Management" },
                { link: "/deleteAccount", title: "Delete Account Request" },
              ],'true')}

              {renderSubMenu("users", "mdi mdi-account", "Users", [
                { link: "/users", title: "User Management" },
                { link: "/deleteAccount", title: "Delete Account Request" },
                { link: "/sub-admin", title: "SubAdmin Management" },
              ])}


              {renderSubMenu("adminManagement", "mdi mdi-account", "Role Access", [
                { link: "/roles", title: "Role Access Management" },
              ])}

              {renderSubMenu("businessManagement", "mdi mdi-city", "Business", [
                { link: "/business", title: "Business Management" },
                { link: "/venues", title: "Venue Management" },
                { link: "/venuecheckin", title: "Checkin Management" },
                { link: "/galleries", title: "Gallery Management" },
                { link: "/music", title: "Music Management" },
                { link: "/feature", title: "Feature Management" },
                { link: "/themes", title: "Theme Management" },
                { link: "/cuisines", title: "Cuisine Management" },
                { link: "/cuisines", title: "Cuisine Management" },
                { link: "/ratings", title: "Rating Management" },
                { link: "/follows", title: "Follow Management" },
              ])}
              {renderSubMenu("appManagement", "mdi mdi-apps", "App", [
                { link: "/pages", title: "Setting Management" },
                { link: "/activityIcon", title: "Icon Management" },
              ])}
              {renderSubMenu("categoryManagement", "mdi mdi-tooltip-edit", "Category", [
                { link: "/category", title: "Category Management" },
                { link: "/banner", title: "Banner Management" },
              ])}

              {renderSubMenu("subscriptionManagement", "mdi mdi-cash", "Subscription", [
                { link: "/package", title: "Package Management" },
                { link: "/promocode", title: "Promocode Management" },
                { link: "/subscribe", title: "Subscribe Management" },
                { link: "/orders", title: "Order Management" },
              ])}

              {renderSubMenu("offerManagement", "mdi mdi-map", "Offers", [
                { link: "/public-holiday", title: "Public Holiday" },
                { link: "/offer", title: "Offer Management" },
                { link: "/specialOffer", title: "Discount" },
                { link: "/specialOfferclaim", title: "Discount Claimed" },
                { link: "/offerOrder", title: "Offer Orders" },
                { link: "/offerSalesReport", title: "Sales Report" },
                { link: "/offerClaimReport", title: "Claim Report" },
                { link: "/offerNotification", title: "Offer Notification" },
              ])}

              {renderSubMenu("offerManagement", "mdi mdi-cube-outline", "Rayna", [
                { link: "/rayna-tickets", title: "Rayna Tickets" },
                { link: "/custom/tickets", title: "Custom Ticket" },
                { link: "/rayna-bookings", title: "Bookings"}               
              ])}

              {renderMenuItem("HotDealManagement", "/vouchers", "mdi mdi-walk", "Deal Management")}

                  {renderSubMenu("promoterAccess", "fas fa-users", "Promoter", [
                { link: "/promoter", title: "Promoter Management" },
                { link: "/paid-pass", title: "Paidpass Management" },
                { link: "/paid-pass-purchase", title: "Paidpass Purchase" },
                { link: "/venue/paid-pass", title: "Venue Paidpass" },
                { link: "/promoter-sub-admin", title: "Promoter SubAdmin" },
                { link: "/conversation", title: "Conversation" },
              ])} 

              {renderMenuItem("ring", "/ring", "fas fa-users", "Ring Management")}
              {renderSubMenu("penalty", "fas fa-users", "Penalty Management",[
                { link: "/penalty", title: "Penalty Management" },
                { link: "/update-policy", title: "Update Policy" },
              ])}

              {renderMenuItem("logs", "/Log-Management-list", "fas fa-users-cog", "Log Management")}
              {renderMenuItem("plusone-user", "/plusone-user", "fas fa-users-cog", "Plusone Users")}


              {renderSubMenu("HomeBlockManagement", "mdi mdi-cube-outline", "Home Blocks", [
                { link: "/homeblock", title: "Home Block Management" },
                { link: "/searchblock", title: "Search Block Management" },
                { link: "/videos", title: "Video Management" },
                { link: "/customComponents", title: "CustomComponent Management" },
                { link: "/dealOrder", title: "Deal Orders" },
                { link: "/blocksize", title: "Size Management" },
                { link: "/stories", title: "Story Management" },
                { link: "/home-block/logs", title: "Home Block Logs" },
              ])}

              {renderSubMenu("yachtManagement", "mdi mdi-ship-wheel", "Yacht", [
                { link: "/yachtclub", title: "Yacht Club" },
                { link: "/yacht", title: "Yacht Management" },
                { link: "/yacht-offer-list", title: "Yacht Offer" },
                { link: "/yachtgallery", title: "Yacht Gallery" },
                { link: "/yachtrating", title: "Yacht Rating" },
                { link: "/Yacht-icon-Management-list", title: "Add On Management" },
                { link: "/yachtfollow", title: "Yacht Follow" }
              ])}


              {renderSubMenu("eventManagement", "mdi mdi-camera-iris", "Event Organizer", [
                { link: "/eventOrganizer", title: "Organizer Management" },
                { link: "/events", title: "Event Management" },
                { link: "/eventGallery", title: "Gallery Management" },
                { link: "/eventRating", title: "Rating Management" },
                { link: "/eventFollow", title: "Follow Management" },
                { link: "/eventOrders", title: "Event Orders" }
              ])}

              {renderMenuItem("outingManagement", "/outinglist", "mdi mdi-walk", "Outing Management")}
              {renderMenuItem("bucketManagement", "/bucketlist", "mdi mdi-heart", "Bucket Management")}


              {renderSubMenu("activityManagement", "mdi mdi-walk", "Activity", [
                { link: "/activityProvider", title: "Provider Management" },
                { link: "/activityType", title: "Type Management" },
                { link: "/activityManagement", title: "Activity Management" },
                { link: "/activityBanner", title: "Banner Management" },
                { link: "/activityRating", title: "Rating Management" },
                { link: "/activityFollow", title: "Follow Management" },
                { link: "/activityOrder", title: "Activity Orders" }
              ])}

              {renderSubMenu("NotificationManagement", "mdi mdi-bell-ring", "Notification", [
                { link: "/notification", title: "Notification" },
                { link: "/notificationread", title: "Notification Read" }
              ])}

              {renderSubMenu("adManagement", "mdi mdi-google-ads", "Ads", [
                { link: "/AdsList", title: "Ad Management" },
                { link: "/adLogs", title: "Ad Logs" }
              ])}

              {renderMenuItem("NotificationManagement", "/inAppNotification", "mdi mdi-message", "In-app Notification")}
              {renderMenuItem("dashboard", "/contactUs", "mdi mdi-account-box", "Contact Us")}


            </ul>
          </div>
        </SimpleBar>
      ) : (
        <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
          <div id="sidebar-menu">
            <ul className="metismenu list-unstyled" id="side-menu">
              {renderMenuItem("dashboard", "/business-account/dashboard", "mdi mdi-view-dashboard", "Dashboard")}
              {renderSubMenu("businessManagement", "mdi mdi-city", "Business", [
                { link: "/business", title: "Business Management" },
                { link: "/venues", title: "Venue Management" },
              ])}
              {renderSubMenu("offerManagement", "mdi mdi-map", "Offers", [
                { link: "/offer", title: "Offer Management" },
                { link: "/specialOffer", title: "Discount" },
              ])}
            </ul>
          </div>
        </SimpleBar>
      )}
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));
