import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { ToastContainer, toast } from "react-toastify";
import "sweetalert2/src/sweetalert2.scss";
import Lightbox from "react-image-lightbox";
import {
  deleteConfirmationWords,
  postRequestForm,
} from "../../components/Common/Utils.js";
import Tippy from "@tippy.js/react";
import "tippy.js/dist/tippy.css";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import FilterModal from "../../components/Modal/FilterModal.js";
import CustomAvatar from "../../components/Common/Avatar.js";
import {
  Row,
  Col,
  Card,
  CardBody,
  Spinner,
  Modal,
  Button,
} from "reactstrap";
import {
  postRequest,
  callCSVRequest,
  deleteRequest,  
  convertTimeToFormattedString,
} from "../../components/Common/Utils.js";

import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const filterObj = {
  title: "",
  subTitle: "",
  startColor: "",
  endColor: "",
  status: "",
  createdAt: "",
};

const VenuePaidpassList = ({itemId}) => {
  const [modal_center, setmodal_center] = useState(false);
  const [resetSearch, setResetSearch] = useState(false);
  const [categorydata, setcategorydata] = useState([]);
  const [loader, setLoader] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [selectedItems, setselectedItems] = useState([]);
  const [sort, setsort] = React.useState("order");
  const [sortOrder, setsortOrder] = React.useState("asc");
  const [showOrder, setShowOrder] = useState(false);
  const [currentFPage, setCurrentFPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [sizePerPageOrg, setSizePerPage] = useState(10);
  const [image, setImage] = React.useState("");
  const [openLightbox, setOpenLightBox] = React.useState(false);
  const [categorydataAll, setcategorydataAll] = useState([]);
  const [filter, setFilter] = useState({ ...filterObj });
  const [model, setModel] = useState(false);
  const [promoterModel, setpromoterModel] = useState(false);
  const [requestId, setrequestId] = useState("");

  useEffect(() => {
    promoterManagment();
  }, [currentPage]);

  useEffect(() => {
    promoterManagment();
  }, [sizePerPageOrg, sort, sortOrder]);

  const GetValueOnChange = async (status) => {
    const formData = new FormData();
    formData.append("requestId", requestId);
    formData.append("status", status);

    const res = await postRequestForm("promoter/request/verify", formData);

    if (res.status == 1) {
      toast.success("promoter Status updated Successfully");
      promoterManagment();
      setpromoterModel(false);
    } else {
      toast.error("Something went wrong");
      setLoader(false);
    }
  };
  const closeModal = () => {
    setModel(false);
  };

  const toggleModel = () => {
    setModel(!model);
  };  

  const updateFilter = (key, value) => {
    const filterObj = { ...filter };
    filterObj[key] = value;
    setFilter(filterObj);
  };

  const applyFilter = () => {
    const filterArgs = [];
    Object.keys(filter).forEach((key) => {
      if (
        filter[key] !== "" &&
        filter[key] !== null &&
        filter[key] !== undefined
      ) {
        if (key === "startColor") {
          var filterObject = {
            "color.startColor": filter["startColor"],
          };
        } else if (key === "endColor") {
          var filterObject = {
            "color.endColor": filter["endColor"],
          };
        } else {
          var filterObject = {
            [key]: filter[key]?.trim(),
          };
        }

        if (
          key === "title" ||
          key === "subTitle" ||
          key === "startColor" ||
          key === "endColor"
        ) {
          filterObject.type = "regex";
        } else if (key === "status") {
          filterObject.type = "eq";
        } else if (key === "createdAt") {
          filterObject.type = "date-eq";
        }

        filterArgs.push(filterObject);
      }
    });

    toggleModel();
    promoterManagment(filterArgs);
  };

  const resetFilter = () => {
    setFilter({ ...filterObj });
    toggleModel();
    promoterManagment();
  };

  const handleTableChange = (type, { page, sizePerPage, searchText }) => {
    if (type === "pagination") {
      if (page != currentPage) {
        setCurrentPage(page);
      }
    }
  };

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }  
 

  const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: '10px',
          // color: 'black', 
          zIndex: 1
        }}
        onClick={onClick}
      >
        {/* <i className="fas fa-chevron-right" style={{ color: 'black', fontSize: '24px' }}></i> */}
      </div>
    );
  };

  const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: '10px',
          zIndex: 1
        }}
        onClick={onClick}
      >
        {/* <i className="fas fa-chevron-left" style={{ color: 'black', fontSize: '24px' }}></i> */}
      </div>
    );
  };
  

  function SNoFormatter(cellContent, row, i) {
    return <>{i + 1}</>;
  }

  const components = [
    <div className="row mb-4" key="title">
      <label className="col-sm-3 col-form-label">Name</label>
      <div className="col-sm-6">
        <input
          type="text"
          value={filter.name}
          onChange={(e) => updateFilter("name", e.target.value)}
          name="title"
          className="form-control"
        />
      </div>
    </div>,

    <div className="row mb-4" key="title">
      <label className="col-sm-3 col-form-label">Email</label>
      <div className="col-sm-6">
        <input
          type="text"
          value={filter.email}
          onChange={(e) => updateFilter("email", e.target.value)}
          name="title"
          className="form-control"
        />
      </div>
    </div>,
    <div className="row mb-4" key="title">
      <label className="col-sm-3 col-form-label">Disclaimer</label>
      <div className="col-sm-6">
        <input
          type="text"
          value={filter.disclaimer}
          onChange={(e) => updateFilter("disclaimer", e.target.value)}
          name="title"
          className="form-control"
        />
      </div>
    </div>,

    <div className="row mb-4" key="title">
      <label className="col-sm-3 col-form-label">Status</label>
      <div className="col-sm-6">
        <select
          onChange={(e) => updateFilter("status", e.target.value)}
          className="form-select"
          value={filter.status}
        >
          <option value="">Select Status</option>
          <option value="pending">Pending</option>
          <option value="accepted">Accepted</option>
          <option value="rejected">Rejected</option>
        </select>
      </div>
    </div>,

    <div className="row mb-4" key="title">
      <label className="col-sm-3 col-form-label">Created Date</label>
      <div className="col-sm-6">
        <input
          type="date"
          value={filter.createdAt}
          onChange={(e) => updateFilter("createdAt", e.target.value)}
          name="createdAt"
          className="form-control"
        />
      </div>
    </div>,

    <div className="mt-5 mt-lg-4" key="submit">
      <div className="row justify-content-end">
        <div className="col-sm-9">
          <div>
            <button
              type="button"
              onClick={applyFilter}
              className="btn btn-primary w-md"
            >
              Filter
            </button>
            <button
              onClick={resetFilter}
              type="button"
              className="btn btn-danger w-md mx-4"
            >
              Reset
            </button>
          </div>
        </div>
      </div>
    </div>,
  ];


  const TypeFormatter = (cell) => {
    return cell.charAt(0).toUpperCase() + cell.slice(1);
  };

  const AmountFormatter = (cell) => {
    return `${cell.toFixed(2)} AED`;
  };

  const DateFormatter = (cell) => {
    const date = new Date(cell);
    return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
  };

  const StatusFormatter = (cell) => {
    return cell ? (
      <span className="badge bg-success">Active</span>
    ) : (
      <span className="badge bg-secondary">Inactive</span>
    );
  };

  const DescriptionFormatter = (cell, row) => {
    const maxLength = 50;
    const displayText = cell.length > maxLength ? `${cell.substring(0, maxLength)}...` : cell;
    return (
      <div
        style={{
          maxHeight: '3em',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          WebkitLineClamp: 2,
          WebkitBoxOrient: 'vertical',
        }}
        title={cell}
      >
        {displayText}
      </div>
    );
  };

//   const columns = [
//     {
//         text: "S.NO",
//         formatter: SNoFormatter,
//         sort: true,
//       },
   
//     {
//       dataField: "venueInfo",
//       text: "Venue Info",
//       formatter: (cell) => (
//         <div>
//           <p><strong>Name:</strong> {cell?.name || "N/A"}</p>
//           <p><strong>Address:</strong> {cell?.address || "N/A"}</p>
//           <img
//             src={cell?.logo || "https://via.placeholder.com/50"}
//             alt="Venue Logo"
//             width={50}
//           />
//         </div>
//       ),
//     },
//     {
//       dataField: "promoterInfo",
//       text: "Promoter Info",
//       formatter: (cell) => (
//         <div>
//           <p><strong>Name:</strong> {`${cell?.first_name || ""} ${cell?.last_name || ""}`}</p>
//           <p><strong>Email:</strong> {cell?.email || "N/A"}</p>
//           <img
//             src={cell?.image || "https://via.placeholder.com/50"}
//             alt="Promoter Image"
//             width={50}
//           />
//         </div>
//       ),
//     },
//     {
//       dataField: "paidPassInfo",
//       text: "Paid Pass Info",
//       formatter: (cell) => (
//         <div>
//           <p><strong>Title:</strong> {cell?.title || "N/A"}</p>
//           <p><strong>Amount:</strong> {cell?.amount ? `${cell.amount} AED` : "N/A"}</p>
//           <p><strong>Validity:</strong> {cell?.validityInDays ? `${cell.validityInDays} Days` : "N/A"}</p>
//         </div>
//       ),
//     },
//     {
//         text: "Actions",
//         formatter: IconFormatter,
//         sort: false,
//       },
//   ];

// Additional formatters for better UI
const VenueInfoFormatter = (venueInfo) => {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <img
          src={venueInfo?.logo || "https://via.placeholder.com/50"}
          alt="Venue Logo"
          style={{ width: 50, height: 50, borderRadius: '50%', marginRight: '10px' }}
        />
        <div>
          <div style={{ fontWeight: '600' }}>{venueInfo?.name || "N/A"}</div>
          <div style={{ fontSize: '0.9em', color: '#6c757d' }}>{venueInfo?.address || "N/A"}</div>
        </div>
      </div>
    );
  };
  
  const PromoterInfoFormatter = (promoterInfo) => {
    const fullName = `${promoterInfo?.first_name || ''} ${promoterInfo?.last_name || ''}`.trim();
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <img
          src={promoterInfo?.image || "https://via.placeholder.com/50"}
          alt="Promoter"
          style={{ width: 50, height: 50, borderRadius: '50%', marginRight: '10px', objectFit: 'cover' }}
        />
        <div>
          <div style={{ fontWeight: '600' }}>{fullName || "N/A"}</div>
          <div style={{ fontSize: '0.9em', color: '#6c757d' }}>{promoterInfo?.email || "N/A"}</div>
        </div>
      </div>
    );
  };
  
  const PaidPassInfoFormatter = (paidPassInfo) => {
    return (
      <div style={{ lineHeight: '1.2' }}>
        <div style={{ fontWeight: '600' }}>{paidPassInfo?.title || "N/A"}</div>
        <div style={{ fontSize: '0.9em', color: '#6c757d' }}>
          Amount: {paidPassInfo?.amount ? `${paidPassInfo.amount} AED` : "N/A"}
        </div>
        <div style={{ fontSize: '0.9em', color: '#6c757d' }}>
          Validity: {paidPassInfo?.validityInDays ? `${paidPassInfo.validityInDays} Days` : "N/A"}
        </div>
      </div>
    );
  };
  
  // Update your columns to use these new formatters
  const columns = [
    {
      text: "S.NO",
      formatter: SNoFormatter,
      sort: true,
      headerStyle: { width: '60px' }
    },
    {
      dataField: "venueInfo",
      text: "Venue",
      formatter: VenueInfoFormatter,
      headerStyle: { width: '250px' }
    },
    {
      dataField: "promoterInfo",
      text: "Promoter",
      formatter: PromoterInfoFormatter,
      headerStyle: { width: '250px' }
    },
    {
      dataField: "paidPassInfo",
      text: "Paid Pass",
      formatter: PaidPassInfoFormatter,
      headerStyle: { width: '200px' }
    },
    {
      dataField: "createdAt",
      text: "Created At",
      formatter: DateFormatter,
      headerStyle: { width: '200px' },
      sort: true,
    },

    {
      text: "Actions",
      formatter: IconFormatter,
      sort: false,
      headerStyle: { width: '100px' }
    },
  ];
  const defaultSorted = [
    {
      dataField: "_id",
      order: "asc",
    },
  ];
  

  const handleChange = (event) => {
    setSizePerPage(event.target.value);
  };
  const handlesorting = (event) => {
    setsort(event.target.value);
  };
  const handlesortOrder = (event) => {
    setsortOrder(event.target.value);
  };
  const handleReload = () => {
    setResetSearch(true);
    setSearchText("");
  };

  useEffect(() => {
    if (!resetSearch) return;
    if (currentPage == 1) {
      promoterManagment([], true);
      return;
    }
    setCurrentPage(1);
  }, [resetSearch]);

  function IconFormatter(cellContent, row) {
    return (
      <React.Fragment>
        <div className="">
          {/* <Tippy content="Detail">
            <button type="button" className=" btn btn-primary text-light">
              {" "}
              <Link
                to={{
                  pathname: `/categorydetails/${row._id}`,
                  state: { row },
                }}
              >
                {" "}
                <i className="fal fa-eye text-light"></i>
              </Link>{" "}
            </button>
          </Tippy> */}
          {/* <Tippy content="Edit">
            <button type="button" className=" btn btn-info  mx-2">
              {" "}
              <Link
                to={{
                  pathname: `/paid-pass/update`,
                  state: { row },
                }}
              >
                {" "}
                <i class="fal fa-pencil fs-5 text-light"></i>
              </Link>{" "}
            </button>
          </Tippy> */}
          <Tippy content="Delete">
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => handlerdelete(row._id)}
            >
              <i class="fal fa-trash  fs-5"></i>
            </button>
          </Tippy>
          &nbsp;&nbsp;
        </div>
      </React.Fragment>
    );
  }

  const promoterManagment = async (filterArgs = []) => {
    setLoader(true);
    const payload = {
      limit: sizePerPageOrg,
      page: currentPage,
      sortField: sort,
      sortOrder: sortOrder,
      venueId: itemId,
    };

    if (filterArgs?.length) {
      payload.filterArgs = filterArgs;
    } else {
      if (searchText && resetSearch === false) {
        payload.search = searchText.trim();
      }
    }
    try {
      const response = await postRequest(`promoter/venue/paid-pass/list`, payload);
      const data = response.data;
      if (resetSearch) {
        setResetSearch(false);
      }
      setTotalPage(data.count);
      setCurrentFPage(data.page);
      setcategorydata(data.list);
    } catch (error) {
      console.error("Failed to fetch data:", error);
    } finally {
      setLoader(false);
    }
  };

  const promoterManagmentAll = async (filterArgs = []) => {
    setLoader(true);
    const payload = {
      limit: 100000,
      page: 1,
      sortField: "order",
      sortOrder: "asc",
    };

    if (filterArgs?.length) {
      payload.filterArgs = filterArgs;
    } else {
      if (searchText) {
        payload.search = searchText;
      }
    }
    try {
      const response = await postRequest(`promoter/request/list`, payload);
      const data = response.data;
      setcategorydataAll(data.list);
    } catch (error) {
      console.error("Failed to fetch data:", error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    promoterManagmentAll();
  }, []);

  const handlerdelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, proceed",
    }).then((result) => {
      if (result.isConfirmed) {
        showRandomWordDialog(id);
      }
    });
  };

  const showRandomWordDialog = (id) => {
    const randomIndex = Math.floor(
      Math.random() * deleteConfirmationWords.length
    );
    const randomWord = deleteConfirmationWords[randomIndex];

    Swal.fire({
      title: "Confirmation Required",
      text: `To confirm deletion, please enter the word: ${randomWord}`,
      icon: "warning",
      input: "text",
      inputPlaceholder: `Type '${randomWord}' to confirm`,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed && result.value === randomWord) {
        deleteCategory(id);
      } else if (result.isConfirmed) {
        Swal.fire(
          "Incorrect Entry",
          "Please enter the correct word to confirm.",
          "error"
        );
      }
    });
  };

  const deleteCategory = async (id) => {
    const response = await deleteRequest(`promoter/venue/paid-pass/delete`, {
        id: id,
    });
    if (response.status) {
      Swal.fire("Deleted!", "Deleted successfully");
      promoterManagment();
    } else {
      Swal.fire("Error!", "Something went wrong");
    }
  }; 



  const selectRow = {
    mode: "checkbox",
    clickToSelect: false,
    style: { background: "#c8e6c9" },
    onSelect: (row, isSelect, rowIndex, e) => {
      if (isSelect) {
        setselectedItems([...selectedItems, row._id]);
      } else {
        setselectedItems(selectedItems.filter((x) => x !== row._id));
      }
    },
    onSelectAll: (isSelect, rows, e) => {
      if (!isSelect) {
        setselectedItems([]);
        return;
      }

      const ids = rows.map((r) => r._id);
      setselectedItems(ids);
    },
  };  

  const rowClasses = (row, rowIndex) => {
    return selectedItems.includes(row._id) ? "selected-row-class" : "";
  };

  return (
    <>
      <React.Fragment>
        <ToastContainer />
        <DndProvider backend={HTML5Backend}>
          <Row>
            
           
            <Col className="col-12">
              <Card>
                {loader ? (
                  <CardBody style={{ height: "100px" }}>
                    <Spinner
                      color="info"
                      style={{
                        height: "4rem",
                        width: "4rem",
                        position: "absolute",
                        left: "50%",
                      }}
                    >
                      Loading...
                    </Spinner>
                  </CardBody>
                ) : (
                  <CardBody className="table-shorting">
                    {
                      <PaginationProvider
                        pagination={paginationFactory({
                          page: currentFPage,
                          sizePerPage: sizePerPageOrg,
                          totalSize: totalPage,
                          custom: true,
                        })}
                      >
                        {({ paginationProps, paginationTableProps }) => (
                          <>
                            <ToolkitProvider
                              keyField="_id"
                              data={categorydata}
                              columns={columns}
                            >
                              {(props) => (
                                <Row>
                                  <Col className="col-lg-12 d-flex mb-3">
                                    <label className="mb-0 text-label">
                                      Page Size
                                    </label>

                                    <select
                                      value={sizePerPageOrg}
                                      onChange={handleChange}
                                      className="form-select"
                                      style={{ width: "10%" }}
                                    >
                                      <option value="10">10</option>
                                      <option value="20">20</option>

                                      <option value="50">50</option>
                                    </select>

                                    <label className="mb-0 text-label ms-3">
                                      Sort By:
                                    </label>
                                    <select
                                      value={sort}
                                      onChange={handlesorting}
                                      className="form-select"
                                      style={{ width: "25%" }}
                                    >
                                     
                                      <option value="createdAt">
                                        Created At
                                      </option>
                                    </select>
                                    <label className="mb-0 text-label ms-3">
                                      Sort Order:
                                    </label>
                                    <select
                                      value={sortOrder}
                                      onChange={handlesortOrder}
                                      className="form-select"
                                      style={{ width: "20%" }}
                                    >
                                      <option value="asc"> Ascending</option>
                                      <option value="desc">Descending</option>
                                    </select>
                                    <div className="filter-item">                                      
                                      <input
                                        type="search"
                                        placeholder="Search"
                                        value={searchText}
                                        class="form-control"
                                        onChange={(e) =>
                                          setSearchText(e.target.value)
                                        }
                                        style={{ width: "30%" }}
                                      />
                                      <button
                                        onClick={() => {
                                          promoterManagment();
                                        }}
                                        type="button"
                                        className="btn btn-primary mx-2"
                                      >
                                        Search
                                      </button>
                                      <button
                                        type="button"
                                        onClick={handleReload}
                                        className="btn btn-danger"
                                      >
                                        Reset
                                      </button>
                                      {/* <button
                                        type="button"
                                        onClick={toggleModel}
                                        className="btn btn-info mx-2"
                                      >
                                        Filter
                                      </button>                                      */}
                                    </div>
                                  </Col>

                                  <Col className="col-lg-12 text-end">
                                    <BootstrapTable
                                      onTableChange={handleTableChange}
                                      noDataIndication={() => (
                                        <div>No data available</div>
                                      )}
                                      remote={true}
                                      selectRow={selectRow}
                                      rowClasses={rowClasses}
                                      // classes="table-custom-striped"
                                      classes="table-custom-border"
                                      {...props.baseProps}
                                      {...paginationTableProps}
                                    />
                                  </Col>
                                </Row>
                              )}
                            </ToolkitProvider>

                            <Row>
                              <Col className="col-lg-12 text-end">
                                <div className="text-md-right">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </>
                        )}
                      </PaginationProvider>
                    }
                  </CardBody>
                )}
              </Card>
            </Col>
          </Row>
        </DndProvider>
        {openLightbox && (
          <Lightbox
            mainSrc={image}
            onCloseRequest={() => setOpenLightBox(false)}
          />
        )}       
      </React.Fragment>

      <FilterModal
        show={model}
        closeModal={closeModal}
        components={components}
      />

      <Modal
        isOpen={promoterModel}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered={true}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myLargeModalLabel">
            Promoter Verify
          </h5>

          <button
            onClick={() => {
              setpromoterModel(false);
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="d-flex align-items-center justify-content-center">
            <button
              type="button"
              onClick={() => GetValueOnChange("accepted")}
              className="btn btn-primary mx-2"
            >
              Accept
            </button>
            <button
              type="button"
              onClick={() => GetValueOnChange("rejected")}
              className="btn btn-danger"
            >
              Reject
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default VenuePaidpassList;
