import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Alert,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import classnames from "classnames";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const RaynaDetailPage = () => {
  const location = useLocation();
  const state = location.state?.row;

  // Extract top-level data
  const {
    name,
    countryName,
    cityName,
    rating,
    duration,
    tourShortDescription,
    imagePath,
    tourOptionData,
  } = state || {};

  // Check if tourOptionData is available
  const hasTourOptions = tourOptionData && tourOptionData.length > 0;

  // State to handle active tab
  const [activeTab, setActiveTab] = useState(null);

  useEffect(() => {
    if (hasTourOptions && tourOptionData.length > 0) {
      // Automatically open the first tab
      setActiveTab(tourOptionData[0].tourOptionId);
    }
  }, [hasTourOptions, tourOptionData]);

  const toggleTab = (optionId) => {
    setActiveTab(optionId);
  };

  return (
    <React.Fragment>
      <ToastContainer />

      <Row>
        <Col lg={12} className="mb-4">
          <div className="page-title-box">
            <h4>Rayna Ticket Detail</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item">Rayna Management</li>
              <li className="breadcrumb-item active">Detail Page</li>
            </ol>
          </div>
        </Col>
      </Row>

      {/* Top Detail Section */}
      <Card className="mb-4">
        <CardBody>
          <Row>
            {/* <Col md={4}>
              <div
                style={{
                  width: "100%",
                  height: "200px",
                  backgroundImage: `url(${imagePath || ''})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  borderRadius: "4px",
                  border: "1px solid #ddd",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {!imagePath && <div style={{ fontWeight: "bold" }}>No Image</div>}
              </div>
            </Col> */}
            <Col md={12}>
              <h2>{name || "N/A"}</h2>
              <p>
                <strong>Country:</strong> {countryName || "N/A"} &nbsp;|&nbsp;{" "}
                <strong>City:</strong> {cityName || "N/A"} &nbsp;|&nbsp;{" "}
                <strong>Rating:</strong> {rating || "N/A"} &nbsp;|&nbsp;{" "}
                <strong>Duration:</strong> {duration || "N/A"}
              </p>
              {tourShortDescription && (
                <p dangerouslySetInnerHTML={{ __html: tourShortDescription }} />
              )}
            </Col>
          </Row>
        </CardBody>
      </Card>

      {/* Tour Options Section */}
      {!hasTourOptions ? (
        <Alert color="warning">No tour options available.</Alert>
      ) : (
        <Card>
          <CardBody>
            <h4 className="mb-4">Tour Options</h4>
            <Nav tabs>
              {tourOptionData.map((option, index) => (
                <NavItem key={option._id}>
                  <NavLink
                    className={classnames({ active: activeTab === option.tourOptionId })}
                    onClick={() => { toggleTab(option.tourOptionId); }}
                    style={{ cursor: "pointer" }}
                  >
                    Option {index + 1} #{option.tourOptionId}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>

            <TabContent activeTab={activeTab} className="mt-4">
              {tourOptionData.map((option) => (
                <TabPane tabId={option.tourOptionId} key={option._id}>
                  <Form>
                    <FormGroup>
                      <Label for="optionName">Option Name</Label>
                      <Input type="text" name="optionName" id="optionName" value={option.optionName || ''} readOnly />
                    </FormGroup>
                    <FormGroup>
                      <Label for="childAge">Child Age</Label>
                      <Input type="text" name="childAge" id="childAge" value={option.childAge || ''} readOnly />
                    </FormGroup>
                    <FormGroup>
                      <Label for="infantAge">Infant Age</Label>
                      <Input type="text" name="infantAge" id="infantAge" value={option.infantAge || ''} readOnly />
                    </FormGroup>
                    <FormGroup>
                      <Label for="optionDescription">Option Description</Label>
                      <Input
                        type="textarea"
                        name="optionDescription"
                        id="optionDescription"
                        value={option.optionDescription || ''}
                        readOnly
                        style={{ height: "100px" }}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="cancellationPolicy">Cancellation Policy</Label>
                      <Input
                        type="text"
                        name="cancellationPolicy"
                        id="cancellationPolicy"
                        value={option.cancellationPolicy || ''}
                        readOnly
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="cancellationPolicyDescription">Cancellation Policy Description</Label>
                      <div style={{ border: "1px solid #ced4da", borderRadius: "4px", padding: "8px" }}>
                        {option.cancellationPolicyDescription ? (
                          <div dangerouslySetInnerHTML={{ __html: option.cancellationPolicyDescription }} />
                        ) : (
                          "N/A"
                        )}
                      </div>
                    </FormGroup>
                    <FormGroup>
                      <Label for="childPolicyDescription">Child Policy Description</Label>
                      <div style={{ border: "1px solid #ced4da", borderRadius: "4px", padding: "8px" }}>
                        {option.childPolicyDescription ? (
                          <div dangerouslySetInnerHTML={{ __html: option.childPolicyDescription }} />
                        ) : (
                          "N/A"
                        )}
                      </div>
                    </FormGroup>
                    <FormGroup>
                      <Label for="duration">Duration</Label>
                      <Input
                        type="text"
                        name="duration"
                        id="duration"
                        value={option.duration || ''}
                        readOnly
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="timeZone">Time Zone</Label>
                      <Input type="text" name="timeZone" id="timeZone" value={option.timeZone || ''} readOnly />
                    </FormGroup>
                  </Form>
                </TabPane>
              ))}
            </TabContent>
          </CardBody>
        </Card>
      )}
    </React.Fragment>
  );
};

export default RaynaDetailPage;
