import React, { useState, useEffect } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Table,
  Spinner,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import {
  postRequestForm,
  postRequest,
  customValidator,
} from "../../components/Common/Utils.js";
import Lightbox from "react-image-lightbox";
import VenueModal from "../../components/Modal/VenueModalOrg.js";
import PromoterModal from "components/Modal/PromoterModal.js";
import PaidpassModal from "components/Modal/PaidpassModal.js";


export default function CreateVenuepass() {
  const location = useLocation();
  const state = location.state?.row;

  const [storyData, setStoryData] = useState({
    buttonText: "",
    venueId: "",
    mediaType: "",
    contentType: "",
    selectedImage: "",
    expiresIn: "1-day",
  });
  const [previewImage, setPreviewImage] = useState("");
  const [openLightbox, setOpenLightBox] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [offerImage, setOfferImage] = useState("");
  const [venueList, setVenueList] = useState([]);
  const [venueId, setVenueId] = useState("");
  const [venueText, setVenueText] = useState("");
  const [venueImage, setVenueImage] = useState("");
  const [promoterId, setPromoterId] = useState("");
  const [promoterText, setPromoterText] = useState("");
  const [promoterImage, setPromoterImage] = useState("");
  const [paidpassId, setPaidpassId] = useState("");
  const [paidpassText, setPaidpassText] = useState("");
  const [paidpassImage, setPaidpassImage] = useState("");
  const [offerModel, setOfferModel] = useState(false);
  const [offerList, setOfferList] = useState([]);
  const [offerId, setOfferId] = useState("");
  const [offerText, setOfferText] = useState("");



  const [model, setModel] = useState(false);
  const [pmodel, setPModel] = useState(false);
  const[paidpassmodel, setPaidpassModel] = useState(false);
  const [startLoader, setStartLoader] = useState(false);

  let history = useHistory();

  const { id } = useParams();

  useEffect(() => {
    if (!state) return;
    setVenueId(state?.venueId);
    setVenueText(state?.venueName);
    setVenueImage(state?.venueLogo);
  }, [state]);

  useEffect(() => {
    if (!state) return;
    setPromoterId(state?.promoterId);
    setPromoterText(state?.promoterName);
    setPromoterImage(state?.promoterLogo);
  }, [state]);





  useEffect(() => {
    if (!selectedImage) return;

    // check file type is image or video

    if (storyData?.mediaType == "video") {
      if (selectedImage?.type?.split("/")[0] != "video") {
        toast.error("Please select video file");
        return;
      }
    } else {
      if (selectedImage?.type?.split("/")[0] != "image") {
        toast.error("Please select image file");
        return;
      }
    }

    const reader = new FileReader();
    reader.onload = () => {
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(selectedImage);
  }, [selectedImage]);

  useEffect(() => {
    fetchList();
    fetchOfferList();
  }, []);

  const showLightBox = (previewImage) => {
    setPreviewImage(previewImage);
    setOpenLightBox(true);
  };

  const fetchList = async () => {
    setLoading(true);
    const response = await postRequest("venue/list", { limit: 10000000 });
    if (response.status == 1) {
      setVenueList(response.data.list);
    }
    setLoading(false);
  };

  const fetchOfferList = async () => {
    setLoading(true);
    const response = await postRequest("venue/offer/list/admin", {
      limit: 10000000,
    });
    if (response.status == 1) {
      setOfferList(response.data.list);
    }
    setLoading(false);
  };


  const toggleModel = () => {
    setModel(!model);
  };

  const togglePModel = () => {
    setPModel(!pmodel);
  };

  const togglePaidpassModel = () => {
    setPaidpassModel(!paidpassmodel);

  };

  const closeModal = () => {
    setModel(false);
  };

  const closePModal = () => {
    setPModel(false);
  };

  const closePaidpassModal = () => {
    setPaidpassModel(false);
  };

  const selectRow = (id, name, venue) => {
    setVenueId(id);
    setVenueText(name);
    setVenueImage(venue?.logo);
    closeModal();
  };

  const selectPromoterRow = (id) => {
    setPromoterId(id?._id);
    setPromoterText(id?.first_name);
    setPromoterImage(id?.image);
    closePModal();
  };

  const selectPaidpassRow = (id) => {
    setPaidpassId(id?._id);
    setPaidpassText(id?.title);    
    closePaidpassModal();
  };

  const Createstory = async (e) => {
    e.preventDefault();   

    
      if (!venueId) {
        toast.error("Please select venue");
        return;
      }

      if (!promoterId) {
        toast.error("Please select promoter");
        return;
      }

      if (!paidpassId) {
        toast.error("Please select paidpass");
        return;
      }
    

    setStartLoader(true);
    setLoading(true);

    const payload = {
      venueId: venueId,
      promoterId: promoterId,
      paidPassId: paidpassId,
    };

    const url = "promoter/venue/paid-pass/add";

    const response = await postRequest(url,  payload);
    setStartLoader(false);
    setLoading(false);

    if (response.status == 1) {
      toast.success("Paidpass Added Successfully");
      setTimeout(() => {
        history.goBack();
      }, 3000);
    } else {
      toast.error(response.message);
    }

  };


  return (
    <React.Fragment>
      <ToastContainer />

      <div className="row">
        <div className="col-lg-10">
          <div className="page-title-box">
            <h4>Create Venue Paid Pass</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Venue Paid Pass</li>:{" "}
              <li className="breadcrumb-item">Create Venue Paid Pass</li>
            </ol>
          </div>
        </div>
        <div className="col-md-2 text-end">
          <button
            type="button"
            class="btn btn-primary btn-lg"
            onClick={() => history.goBack()}
          >
            <i class="fas fa-chevron-left"></i> Back
          </button>
        </div>
      </div>

      {/* header */}

      {/* form */}
      <Row>
        <Col lg={12}>
          <Card>
            {loading ? (
              <CardBody style={{ height: "100px" }}>
                <Spinner
                  color="info"
                  style={{
                    height: "4rem",
                    width: "4rem",
                    position: "absolute",
                    left: "50%",
                  }}
                >
                  Loading...
                </Spinner>
              </CardBody>
            ) : (
              <CardBody>
                <h4 className="card-title"> Create Venue Paid Pass </h4>
                <Form onSubmit={Createstory}>
                  <Row>
                    <Col lg={12} className="ms-lg-auto">
                      <div className="mt-5 mt-lg-4">
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Select Venue
                          </label>

                          <div
                            className="col-sm-6"
                            onClick={() => toggleModel()}
                          >
                            {venueText ? (
                              <div class="input-group">
                                <button
                                  class="btn"
                                  style={{
                                    border: "1px solid #ced4da",
                                    background: "#e60489",
                                    color: "#ffff",
                                  }}
                                  type="button"
                                  id="chooseVenueBtn"
                                  onclick={() => toggleModel()}
                                >
                                  Choose Venue
                                </button>
                                <div
                                  style={{
                                    border: "1px solid #ced4da",
                                    width: "16px",
                                  }}
                                ></div>
                                <img
                                  src={venueImage}
                                  style={{
                                    width: "40px",
                                    height: "40px",
                                    border: "1px solid #ced4da",
                                    borderRight: "none",
                                    borderLeft: "none",
                                  }}
                                ></img>
                                <input
                                  type="text"
                                  style={{ borderLeft: "none" }}
                                  class="form-control"
                                  readonly
                                  value={venueText}
                                  id="selectedVenue"
                                  placeholder="No venue chosen"
                                />
                              </div>
                            ) : (
                              <div class="input-group">
                                <button
                                  class="btn"
                                  style={{
                                    border: "1px solid #ced4da",
                                    background: "#e60489",
                                    color: "#ffff",
                                  }}
                                  type="button"
                                  id="chooseVenueBtn"
                                  onclick={() => toggleModel()}
                                >
                                  Choose Venue
                                </button>
                                <input
                                  type="text"
                                  class="form-control"
                                  readonly
                                  id="selectedVenue"
                                  placeholder="No venue chosen"
                                />
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Select Promoter
                          </label>

                          <div
                            className="col-sm-6"
                            onClick={() => togglePModel()}
                          >
                            {promoterText ? (
                              <div class="input-group">
                                <button
                                  class="btn"
                                  style={{
                                    border: "1px solid #ced4da",
                                    background: "#e60489",
                                    color: "#ffff",
                                  }}
                                  type="button"
                                  id="chooseVenueBtn"
                                  onclick={() => togglePModel()}
                                >
                                  Choose Promoter
                                </button>
                                <div
                                  style={{
                                    border: "1px solid #ced4da",
                                    width: "16px",
                                  }}
                                ></div>
                                <img
                                  src={promoterImage}
                                  style={{
                                    width: "40px",
                                    height: "40px",
                                    border: "1px solid #ced4da",
                                    borderRight: "none",
                                    borderLeft: "none",
                                  }}
                                ></img>
                                <input
                                  type="text"
                                  style={{ borderLeft: "none" }}
                                  class="form-control"
                                  readonly
                                  value={promoterText}
                                  id="selectedVenue"
                                  placeholder="No venue chosen"
                                />
                              </div>
                            ) : (
                              <div class="input-group">
                                <button
                                  class="btn"
                                  style={{
                                    border: "1px solid #ced4da",
                                    background: "#e60489",
                                    color: "#ffff",
                                  }}
                                  type="button"
                                  id="chooseVenueBtn"
                                  onclick={() => togglePModel()}
                                >
                                  Choose Promoter
                                </button>
                                <input
                                  type="text"
                                  class="form-control"
                                  readonly
                                  id="selectedVenue"
                                  placeholder="No promoter chosen"
                                />
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Select Paidpass
                          </label>

                          <div
                            className="col-sm-6"
                            onClick={() => togglePaidpassModel()}
                          >
                            {paidpassText ? (
                              <div class="input-group">
                                <button
                                  class="btn"
                                  style={{
                                    border: "1px solid #ced4da",
                                    background: "#e60489",
                                    color: "#ffff",
                                  }}
                                  type="button"
                                  id="chooseVenueBtn"
                                  onclick={() => togglePaidpassModel()}
                                >
                                  Choose Paidpass
                                </button>
                                <div
                                  style={{
                                    border: "1px solid #ced4da",
                                    width: "16px",
                                  }}
                                ></div>                               
                                <input
                                  type="text"
                                  style={{ borderLeft: "none" }}
                                  class="form-control"
                                  readonly
                                  value={paidpassText}
                                  id="selectedVenue"
                                  placeholder="No paidpass chosen"
                                />
                              </div>
                            ) : (
                              <div class="input-group">
                                <button
                                  class="btn"
                                  style={{
                                    border: "1px solid #ced4da",
                                    background: "#e60489",
                                    color: "#ffff",
                                  }}
                                  type="button"
                                  id="chooseVenueBtn"
                                  onclick={() => togglePaidpassModel()}
                                >
                                  Choose Paidpass
                                </button>
                                <input
                                  type="text"
                                  class="form-control"
                                  readonly
                                  id="selectedVenue"
                                  placeholder="No paidpass chosen"
                                />
                              </div>
                            )}
                          </div>
                        </div>
                       
                        <div className="mt-5 mt-lg-4">
                          <div className="row justify-content-end">
                            <div className="col-sm-9">
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-primary w-md"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>
      {openLightbox && (
        <Lightbox
          mainSrc={previewImage}
          onCloseRequest={() => setOpenLightBox(false)}
        />
      )}

      {/* modal */}
     
      <VenueModal show={model} closeModal={closeModal} selectRow={selectRow} />
      <PromoterModal
        show={pmodel}
        closeModal={closePModal}
        selectRow={selectPromoterRow}
      />
      <PaidpassModal
        show={paidpassmodel}
        closeModal={closePaidpassModal}
        selectRow={selectPaidpassRow}
      />


     
    </React.Fragment>
  );
}
