import React, { useState, useEffect, useCallback } from "react";
import { getRequest, postRequest } from "../Common/Utils.js";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import { Link, NavLink } from "react-router-dom";
import ReactPaginate from "react-paginate";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Spinner,
  Badge,
  UncontrolledTooltip,
  Modal,
  Button,
} from "reactstrap";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";

const TableComponent = ({ selectRow, action, passData }) => {
  const [venuedata, setvenuedata] = useState([]);
  const [loader, setLoader] = useState(false);
  const [rowPasses, setRowPasses] = useState({}); // State to track selected passes per row

  useEffect(() => {
    venueManagment();
  }, []);

  const venueManagment = async () => {
    setLoader(true);
    const payload = {
      limit: 10000,
      page: 1,
      unpublished: false,
     
    
      status: true,
      filterArgs: [{ isPromoter: "true", type: "eq" }],
    };
    const response = await postRequest(`user/list/admin`, payload);
    const data = response.data;
    setvenuedata(data.list || []);
    setLoader(false);
  };

  const handlePassChange = (rowId, value) => {
    console.log("Row ID:", rowId);
  
    // Update the rowPasses state
    setRowPasses((prev) => ({
      ...prev,
      [rowId]: value,
    }));
  
    // Update the venuedata to reflect the change
    setvenuedata((prevData) =>
      prevData.map((row) =>
        row._id === rowId
          ? { ...row, selectedPass: value } // Add or update the selectedPass field
          : row
      )
    );
  };
  const handleSelectClick = (row) => {
    const selectedPass = rowPasses[row._id];
    if (selectedPass) {
      console.log("Selected Row:", row);
      console.log("Selected Pass:", selectedPass);
      selectRow(row, selectedPass);
    } else {
      console.error("No pass selected for this row!");
      selectRow(row, "");
    }
  };

  const columns = [
    {
      dataField: "first_name",
      text: "Name",
    },
    {
      dataField: "email",
      text: "Email",
    },
    {
      dataField: "nationality",
      text: "Nationality",
    },
    {
      dataField: "image",
      text: "Image",
      formatter: (cellContent, row) => (
        <img src={row.image} alt="logo img" width="50px" />
      ),
    },
    {
      dataField: "actions",
      text: "Actions",
      formatter: (cellContent, row) => (
        <div className="d-flex align-items-center">
          {/* Dropdown for selecting pass */}
          <select
            className="form-select me-2"
            onChange={(e) => handlePassChange(row._id, e.target.value)}
            value={rowPasses[row._id] || ""}
          >
            <option value="">Select Pass</option>
            {passData?.map((pass) => (
              <option key={pass._id} value={pass._id}>
                {pass.title}
              </option>
            ))}
          </select>
          {/* Button to select the row */}
          <Button
            color="primary"
            onClick={() => handleSelectClick(row)}           
          >
            Select
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div>
      {loader ? (
        <div>Loading...</div>
      ) : (
        <BootstrapTable keyField="_id" data={venuedata} columns={columns} />
      )}
    </div>
  );
};

const PromoterModal = ({ show, closeModal, selectRow, action }) => {
    const [passData, setPassData] = useState([]);
    useEffect(() => {
        fetchPass();    
    }, []);

    const fetchPass = async () => {

        const payload = {
            limit: 2000,
            page: 1,         
            sortField: "order",
            sortOrder: "asc",
            type: "venue",                  
        };

        const response = await postRequest(`promoter/paid-pass/list-for-admin`, payload);
        const data = response.data;
        setPassData(data.list);
       
    };

  return (
    <Modal
      isOpen={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered={true}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myLargeModalLabel">
          Promoter List
        </h5>

        <button
          onClick={() => {
            closeModal();
          }}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button> 

      </div>
      <div className="modal-body">
        <TableComponent selectRow={selectRow} action={action} passData={passData} />
      </div>
    </Modal>
  );
};

export default PromoterModal;
