import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, Spinner } from "reactstrap";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import { useLocation } from "react-router-dom";
import moment from "moment";
import Lightbox from "react-image-lightbox";
import { ToastContainer } from "react-toastify";

export default function PenaltyDetailPage() {
  const location = useLocation();
  const state = location.state.row; // The state passed into this page
  const [loader, setLoader] = useState(false);
  const [image, setImage] = React.useState("");
  const [openLightbox, setOpenLightBox] = React.useState(false);

  // Safely handle data
  const complimentoryUser = state?.complimentoryUser || {};
  const listData = state?.list || [];

  

  const showLightBox = (img) => {
    if (img) {
      setImage(img);
      setOpenLightBox(true);
    }
  };

  // Table columns for the list
  const columns = [
    {
      dataField: "dateTime",
      text: "Date/Time",
      formatter: (cell, row) => (
        <span>{moment(row.dateTime).format("DD-MM-YYYY HH:mm")}</span>
      ),
      sort: false,
    },
    {
      dataField: "action",
      text: "Action",
      sort: false,
    },
    {
      dataField: "targetedVenue",
      text: "Targeted Venue",
      sort: false,
    },
    {
      dataField: "frequencyIncrease",
      text: "Frequency Increase",
      sort: false,
    },
    {
      dataField: "banDuration",
      text: "Ban Duration",
      sort: false,
    },
    {
      dataField: "cost",
      text: "Cost",
      sort: false,
    },
    {
      dataField: "eventInfo",
      text: "Event Details",
      formatter: (cell, row) => {
        const event = row?.event;
        if (!event) return <span>No Event Data</span>;

        const eventImage = event.image || event.customVenue?.image || "";
        const eventVenueName = event.customVenue?.name || "N/A";
        const eventStart = `${event.date} - ${event.startTime}`;
        const eventEnd = `${event.endDate} - ${event.endTime}`;

        return (
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            {eventImage && (
              <img
                src={eventImage}
                alt="Event"
                width={"50px"}
                style={{ borderRadius: "20%", cursor: "pointer" }}
                onClick={() => showLightBox(eventImage)}
              />
            )}
            <div>
              <p><strong>Venue:</strong> {eventVenueName}</p>
              <p><strong>Start:</strong> {eventStart}</p>
              <p><strong>End:</strong> {eventEnd}</p>
            </div>
          </div>
        );
      },
      sort: false,
    },
  ];

  return (
    <>
      <ToastContainer />
      {loader ? (
        <CardBody style={{ height: "100px" }}>
          <Spinner
            color="info"
            style={{
              height: "4rem",
              width: "4rem",
              position: "absolute",
              left: "50%",
            }}
          >
            Loading...
          </Spinner>
        </CardBody>
      ) : (
        <Row>
          <Col lg={12}>
            <div className="page-title-box">
              <h4>Penalty Detail</h4>
              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item active">Penalty</li>:{" "}
                <li className="breadcrumb-item">Details</li>
              </ol>
            </div>
          </Col>

          {/* Complimentory User Information Section */}
          <Col lg={12}>
            <Card className="mb-4">
              <CardBody>
                <h5 className="card-title">Complimentary User Info</h5>
                <div className="row">
                  <div className="col-md-4">
                    <p><strong>Name:</strong> {complimentoryUser.first_name} {complimentoryUser.last_name}</p>
                  </div>
                  <div className="col-md-4">
                    <p><strong>Email:</strong> {complimentoryUser.email}</p>
                  </div>
                  <div className="col-md-4">
                    <p><strong>Phone:</strong> {complimentoryUser.phone}</p>
                  </div>
                  <div className="col-md-4">
                    <p><strong>Gender:</strong> {complimentoryUser.gender}</p>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>

          {/* List Table */}
          <Col lg={12}>
            <Card>
              <CardBody>
                <h5 className="card-title">Penalty Actions</h5>
                {listData.length === 0 ? (
                  <p>No actions found.</p>
                ) : (
                  <ToolkitProvider keyField="_id" data={listData} columns={columns}>
                    {(props) => (
                      <div>
                        <BootstrapTable
                          classes="table-striped"
                          {...props.baseProps}
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}

      {openLightbox && (
        <Lightbox
          mainSrc={image}
          onCloseRequest={() => setOpenLightBox(false)}
        />
      )}
    </>
  );
}
